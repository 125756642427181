<template>
    <div class="wrapper-content-page wrapper-contact-page">
        <div class="block-info">
            <div class="block-info__logo">
                <img
                    :src="require('@/assets/images/main/home-page/Logo.png')"
                    data-aos="fade-down"
                    @click="$router.push('/')"
                />
            </div>

            <h3 class="block-info__title" data-aos="fade-right">Contact Us</h3>

            <p class="desc-text">
                You’ve got questions, we’ve got answers. We’re just a click
                away.
            </p>

            <div class="block-form">
                <div class="block-form__line block-form__line--double">
                    <div class="form-input">
                        <p class="label-text">Name</p>
                        <input type="text" placeholder="Text here" />
                    </div>

                    <div class="form-input">
                        <p class="label-text">Email</p>
                        <input type="text" placeholder="Text here" />
                    </div>
                </div>

                <div class="block-form__line">
                    <div class="form-input">
                        <p class="label-text">Message</p>
                        <textarea placeholder="Text here" rows="8" />
                    </div>
                </div>

                <div class="block-form__line">
                    <button>Submit</button>
                </div>
            </div>

            <div class="block-contact">
                <div class="item">
                    <p class="label-text">Email</p>
                    <a class="email-text" href="mailto:hello@9tales.com">
                        hello@9tales.com
                    </a>
                </div>

                <div class="item">
                    <p class="label-text">Connect</p>
                    <div class="list-menu">
                        <div class="list-menu__item hovered">
                            <p>
                                <a
                                    href="https://vt.tiktok.com/ZSJKTDKMW/"
                                    target="_blank"
                                >
                                    <img
                                        :src="
                                            require('@/assets/images/icon/tiktok.png')
                                        "
                                    />
                                </a>
                            </p>
                        </div>
                        <div class="list-menu__item hovered">
                            <p>
                                <a
                                    href="https://www.instagram.com/ninetales.stories/"
                                    target="_blank"
                                >
                                    <img
                                        :src="
                                            require('@/assets/images/icon/instagram.png')
                                        "
                                    />
                                </a>
                            </p>
                        </div>
                        <div class="list-menu__item hovered">
                            <p>
                                <a
                                    href="https://www.facebook.com/ninetales.stories"
                                    target="_blank"
                                >
                                    <img
                                        :src="
                                            require('@/assets/images/icon/facebook.png')
                                        "
                                    />
                                </a>
                            </p>
                        </div>
                        <div class="list-menu__item hovered">
                            <p>
                                <a
                                    href="https://www.linkedin.com/company/nine-tales"
                                    target="_blank"
                                >
                                    <img
                                        :src="
                                            require('@/assets/images/icon/linkedin.png')
                                        "
                                    />
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="block-menu">
                <div class="list-menu">
                    <div class="list-menu__item hovered">
                        <p>
                            <router-link to="/privacy">Privacy</router-link>
                        </p>
                    </div>
                    <div class="list-menu__item hovered">
                        <p>
                            <router-link to="/terms">Terms</router-link>
                        </p>
                    </div>
                    <div class="list-menu__item hovered">
                        <p>
                            <router-link to="/contact">Contact</router-link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        window.scrollTo(0, 0);
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/page/Term.scss';

.wrapper-contact-page {
    .desc-text {
        font-family: Soleil;
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        color: #4a5533;
    }

    .label-text {
        font-family: Soleil;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #4a5533;
    }

    .block-form {
        margin-top: 2rem;
        &__line {
            display: flex;
            justify-content: space-between;
            &:not(:last-child) {
                margin-bottom: 1.5rem;
            }
            .form-input {
                width: 100%;
                p {
                    margin-bottom: 0.3rem;
                }
                input,
                textarea {
                    background: #ffffff;
                    width: 100%;
                    border: 1px solid #4a5533;
                    box-sizing: border-box;
                    border-radius: 8px;
                    padding: 12px 16px;
                    color: #4a5533;
                    &:focus,
                    &:hover {
                        border: 1px solid #4a5533;
                        outline: none;
                    }
                }
            }
            &--double {
                .form-input {
                    width: 50%;
                    &:first-child {
                        width: 45%;
                    }
                }
            }
            button {
                background: #f4b836;
                border-radius: 24px;
                font-family: Soleil;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #ffffff;
                text-align: center;
                padding: 8px 30px;
                border: none;
                outline: none;
                cursor: pointer;
                &:hover {
                    opacity: 0.8;
                    transition: 0.3s ease-in-out;
                }
            }
        }
    }

    .block-contact {
        margin-top: 3rem;
        display: flex;
        .item {
            &:not(:last-child) {
                margin-right: 5rem;
            }
            .label-text {
                margin-bottom: 1rem;
            }
            .email-text {
                color: #f4b836;
                margin-bottom: 1rem;
                font-family: Soleil;
                font-size: 16px;
                line-height: 35px;
            }
        }

        .list-menu {
            display: flex;

            &__item {
                &:not(:last-child) {
                    margin-right: 1.5rem;
                }
                @include hovered;
                p {
                    font-size: 24px;
                    line-height: 32px;
                    text-align: center;
                    color: #424242;
                    font-family: Soleil;
                }
            }
        }
    }
}

@include mobile {
    .wrapper-contact-page {
        .block-contact {
            flex-direction: column;
            .item {
                &:not(:last-child) {
                    margin-bottom: 2rem;
                }
            }
        }
    }
}
</style>
